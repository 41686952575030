:root {
  --width-size: 100vw;
  --height-size: 100vh;
  --half-width-size: calc(var(--width-size) / 2);
  --half-height-size: calc(var(--height-size) / 2);
  --negative-half-width-size: calc(var(--width-size) * -1 / 2);
  --negative-half-height-size: calc(var(--height-size) * -1 / 2);
}

*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: "Syne Mono", monospace;
  font-weight: 400;
  font-size: 1.6rem;
  color: #777;
  background: url("/developer.jpg") no-repeat center center;
  background-size: cover;
}
