:root {
  --width-size: 100vw;
  --height-size: 100vh;
  --half-width-size: calc(var(--width-size) / 2);
  --half-height-size: calc(var(--height-size) / 2);
  --negative-half-width-size: calc(var(--width-size) * -1 / 2);
  --negative-half-height-size: calc(var(--height-size) * -1 / 2);
}

*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: "Syne Mono", monospace;
  font-weight: 400;
  font-size: 1.6rem;
  color: #777;
  background: url("/developer.jpg") no-repeat center center;
  background-size: cover;
}

.navigation__checkbox {
  display: none;
}

.navigation__button {
  background-color: #fff;
  height: 7rem;
  width: 7rem;
  position: fixed;
  top: 6rem;
  right: 6rem;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}

.navigation__background {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  /* background-image: radial-gradient(#55c57a, #28b485); */
  z-index: 1000;
  transition: all 0.8s cubic-bezier(0.83, 0, 0.17, 1);
  background: url("/navigation.avif") center center;
  background-size: 35%;
  background-position: 30% 55%;
}

.navigation__nav {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  pointer-events: none;
  transition: all 0.8s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  width: 100%;
}

.navigation__item {
  margin: 1rem;
}

.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 230%;
  transition: all 0.4s;
}
.navigation__link:link span,
.navigation__link:visited span {
  margin-right: 1rem;
  display: inline-block;
}

.navigation__link:hover,
.navigation__link:active {
  background-position: 100%;
  color: #7ed56f;
  transform: translateX(1rem);
}

.open ~ .navigation__background {
  transform: scale(80);
  background-size: 35%;
  background-position: 30% 55%;
}

.open ~ .navigation__nav {
  display: block;
  opacity: 1;
  pointer-events: all;
  width: 100%;
}

.navigation__icon {
  position: relative;
  margin-top: 3.5rem;
}
.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  width: 3rem;
  height: 2px;
  background-color: #333;
  display: inline-block;
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -0.8rem;
}
.navigation__icon::after {
  top: 0.8rem;
}

.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}

.navigation__button:hover .navigation__icon::after {
  top: 1rem;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.about-card {
  position: relative;
  height: 80%;
  width: 30%;
  border-radius: 8px;
  background-color: #fff;
}

.about-card::before {
  position: absolute;
  border-radius: 8px;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #52c47b;
  transform-origin: 50% 50%;
  transform: rotate(10deg);
  z-index: -1;
}

.about-card-content {
  padding: 2rem;
}

.profile {
  text-align: center;
}

.user-image {
  width: 8.5rem;
  height: 8.5rem;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.about-me-text {
  margin-top: 2rem;
  font-size: 1.8rem;
  text-align: center;
  font-weight: bold;
}

.about-me-heading {
  margin-top: 2rem;
  text-align: center;
  font-weight: bold;
  color: #52c47b;
}

.links {
  text-align: center;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, 0);
  text-transform: uppercase;
}

.link-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.link-icons a {
  margin-top: 0.5rem;
}

.icon {
  font-size: 2.5rem;
  margin-right: 1rem;
}

.icon:hover {
  color: #52c47b;
}

.skill-wrapper {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  text-align: center;
}

.skill-title {
  text-align: center;
  margin: 2rem auto;
  color: #52c47b;
  background-color: #fff;
  display: inline-flex;
  padding: 1rem;
}

.skill-cards {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0 75px;
}

.skill-card {
  margin: 2rem;
  display: inline-block;
  width: 240px;
  text-align: center;
}

.icon-container {
  height: 230px;
  width: 200px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.icon-container svg {
  font-size: 150px;
}

.inner-skew {
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: rgb(241, 241, 243);
  height: 230px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #fff;
  margin: -120px 0px 0px 0px;
  font-size: 14px;
  height: 260px;
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #52c47b;
  font-size: 18px;
}

.container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    overflow-x: hidden;
}

.timeline {
    width: 80%;
    height: auto;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.timeline ul {
    list-style: none;
}

.timeline ul li {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.timeline ul li:last-child {
    margin-bottom: 0;
}

.timeline-content h1 {
    font-weight: 600;
    font-size: 2rem;
    color: #52c47b
}

.timeline-content h5 {
    font-size: 1.2rem;
    color: gray
}

.timeline-content p {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0;
    font-weight: 300;
    color: black;
}

.sub-timeline-items{
    position: relative;
}

.sub-timeline-item p{
    margin-bottom: 0;
}

.sub-timeline-item:before{
    content: "";
    position: absolute;
    top: 18px;
    transform: translateX(-50%);
    width: 0.8px;
    height: calc(100% - 34px);
    box-shadow: -3px 0 0 rgb(218, 214, 214);
}

.sub-timeline-item p::before{
    content: "";
    position: absolute;
    top:50%;
    transform: translate(-28px,-50%);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgb(218, 214, 214);
}

.sub-timeline-item p{
    position: relative;
    padding-left: 20px;
    margin-bottom: 8px;
}

.sub-timeline-item p span{
    display: block;
    line-height: normal;
    font-size: 1.6rem;
    color:gray
}

@media only screen and (min-width: 768px) {
    .timeline:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0.8px;
        height: 100%;
        background-color: white;
        box-shadow: -3px 0 0 #c4bfbf;
    }

    .timeline ul li {
        width: 50%;
        position: relative;
        margin-bottom: 50px;
        transition: transform 1s ease-out, opacity 0.5s ease-in 0.5s;
        opacity: 0;
    }

    .timeline ul li:nth-child(odd) {
        float: left;
        clear: right;
        transform: translateX(-100%);
        border-radius: 20px 0px 20px 20px;

    }

    .timeline ul li:nth-child(even) {
        float: right;
        clear: left;
        transform: translateX(100%);
        border-radius: 0px 20px 20px 20px;
    }

    .timeline ul .fade-in {
        opacity: 1;
    }

    .timeline ul .fade-in:nth-child(odd) {
        transform: translateX(-30px);
    }

    .timeline ul .fade-in:nth-child(even) {
        transform: translateX(30px);
    }

    .timeline ul li::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: gray;
        top: 0px;
        transition: top 0.5s ease-out;
    }

    .timeline ul li:nth-child(odd)::before {
        transform: translate(50%, -50%);
        right: -30px;
    }

    .timeline ul li:nth-child(even)::before {
        transform: translate(-50%, -50%);
        left: -30px;
    }

    .timeline ul li:hover::before {
        background-color: #52c47b;
        top: 50%
    }
}
.project-card {
    margin: 1rem;
    width: 240px;
    min-height: 250px;
    text-align: center;
    /* background-color: #5F6979;
     */
     background-color: #ffffff;
     color:#000000;
    line-height: normal;
    border-radius: 6px;
    border-top: 3px solid var(--line-color);
    padding: 1rem;
    position: relative;
}

.project-card:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
}

.project-card-title {
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--line-color);
}

.project-card-content {
    font-size: 1.6rem;
    font-weight: normal;
}

.project-card .links {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    width: 100%;
}



.links a {
    display: flex;
    color: var(--line-color);
    text-decoration: none;
    font-size: 2.6rem;
    width: 50%;
    border-top: 1px solid var(--line-color);
    padding: 1rem;
}


.links a:first-child {
    border-right: 1px solid var(--line-color);

}

.links a svg{
    margin: auto;
}

.links a:hover>svg {
    -webkit-animation: shake 800ms infinite;
            animation: shake 800ms infinite;
}

@-webkit-keyframes shake {
    100%,0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(25deg);
    }
}

@keyframes shake {
    100%,0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(25deg);
    }
}
.scene {
  width: var(--width-size);
  height: var(--height-size);
  perspective: calc(var(--height-size) + var(--width-size));
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.box.show-front {
  transform: translateZ(var(--negative-half-width-size)) rotateY(0deg);
}
.box.show-back {
  transform: translateZ(var(--negative-half-width-size)) rotateY(-180deg);
}

.box.show-right {
  transform: translateZ(var(--negative-half-width-size)) rotateY(-90deg);
}

.box.show-left {
  transform: translateZ(var(--negative-half-width-size)) rotateY(90deg);
}

.box.show-top {
  transform: translateZ(var(--negative-half-height-size)) rotateX(-90deg);
}

.box.show-bottom {
  transform: translateZ(var(--negative-half-height-size)) rotateX(90deg);
}

.box.show-bottom > .box__face--right {
  width: var(--height-size);
  transform: rotateY(90deg)
    translateZ(
      calc(
        var(--half-width-size) + var(--half-width-size) -
          var(--half-height-size)
      )
    );
}

.box.show-bottom > .box__face--front {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-bottom > .box__face--back {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-top > .box__face--front {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-top > .box__face--back {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-top > .box__face--right {
  width: var(--height-size);
  transform: rotateY(90deg)
    translateZ(
      calc(
        var(--half-width-size) + var(--half-width-size) -
          var(--half-height-size)
      )
    );
}

.box.show-bottom > .box__face--left {
  width: var(--height-size);
  transform: rotateY(-90deg) translateZ(var(--half-height-size));
}

.box.show-top > .box__face--left {
  width: var(--height-size);
  transform: rotateY(-90deg) translateZ(var(--half-height-size));
}

.box.show-front > .box__face--top,
.box.show-back > .box__face--top,
.box.show-right > .box__face--top,
.box.show-left > .box__face--top {
  height: var(--width-size);
  transform: rotateX(90deg) translateZ(var(--half-width-size));
}

.box.show-front > .box__face--bottom,
.box.show-back > .box__face--bottom,
.box.show-right > .box__face--bottom,
.box.show-left > .box__face--bottom {
  height: var(--width-size);
  transform: rotateX(-90deg)
    translateZ(calc(var(--height-size) - var(--half-width-size)));
}

.box__face {
  position: absolute;
  font-weight: bold;
  color: white;
  text-align: center;
}

.box__face h1 {
  color: #52c47b;
  display: inline-flex;
  justify-content: center;
  margin-top: 4rem;
  background-color: white;
  padding: 1rem;
}

.box__face--front,
.box__face--back {
  width: var(--width-size);
  height: var(--height-size);
}

.box__face--right,
.box__face--left {
  width: var(--width-size);
  height: var(--height-size);
}

.box__face--top,
.box__face--bottom {
  width: var(--width-size);
  height: var(--height-size);
}

.box__face--front {
  background: url("/react.jpg") no-repeat center center;
  background-size: cover;
  transform: rotateY(0deg) translateZ(var(--half-width-size));
}

.box__face--back {
  background: url("/opensource.jpeg") no-repeat center center;
  background-size: cover;
  transform: rotateY(180deg) translateZ(var(--half-width-size));
}

.box__face--right {
  background: url("/nodejs.webp") no-repeat center center;
  background-size: cover;
  transform: rotateY(90deg) translateZ(var(--half-width-size));
}

.box__face--left {
  background: hsla(0, 5%, 85%, 0.9);
  transform: rotateY(-90deg) translateZ(var(--half-width-size));
}

.box__face--top {
  background: hsla(0, 5%, 85%, 0.9);
  transform: rotateX(90deg) translateZ(var(--half-height-size));
}

.box__face--bottom {
  background: hsla(0, 5%, 85%, 0.9);
  transform: rotateX(-90deg) translateZ(var(--half-height-size));
}

.radio-group {
  z-index: 2;
}

.radio-group label {
  position: absolute;
  margin-right: 10px;
  cursor: pointer;
}

.radio-group input {
  display: none;
}

.labels {
  font-size: 22px;
  background-color: #fff;
  padding: 0.5rem 1rem;
  transform: scale(1);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

.labels:first-child {
  display: none;
}

.labels:nth-child(2) {
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}

.labels:nth-child(3) {
  top: 10px;
  left: 10px;
}

.labels:nth-child(4) {
  top: 50%;
  left: 10px;
  transform: translate(0, 50%);
}

.labels:nth-child(5) {
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.labels:nth-child(6) {
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.project-cards {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: 80%;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


