.scene {
  width: var(--width-size);
  height: var(--height-size);
  perspective: calc(var(--height-size) + var(--width-size));
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.box.show-front {
  transform: translateZ(var(--negative-half-width-size)) rotateY(0deg);
}
.box.show-back {
  transform: translateZ(var(--negative-half-width-size)) rotateY(-180deg);
}

.box.show-right {
  transform: translateZ(var(--negative-half-width-size)) rotateY(-90deg);
}

.box.show-left {
  transform: translateZ(var(--negative-half-width-size)) rotateY(90deg);
}

.box.show-top {
  transform: translateZ(var(--negative-half-height-size)) rotateX(-90deg);
}

.box.show-bottom {
  transform: translateZ(var(--negative-half-height-size)) rotateX(90deg);
}

.box.show-bottom > .box__face--right {
  width: var(--height-size);
  transform: rotateY(90deg)
    translateZ(
      calc(
        var(--half-width-size) + var(--half-width-size) -
          var(--half-height-size)
      )
    );
}

.box.show-bottom > .box__face--front {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-bottom > .box__face--back {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-top > .box__face--front {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-top > .box__face--back {
  transform: rotateY(0deg) translateZ(calc(var(--half-height-size)));
}

.box.show-top > .box__face--right {
  width: var(--height-size);
  transform: rotateY(90deg)
    translateZ(
      calc(
        var(--half-width-size) + var(--half-width-size) -
          var(--half-height-size)
      )
    );
}

.box.show-bottom > .box__face--left {
  width: var(--height-size);
  transform: rotateY(-90deg) translateZ(var(--half-height-size));
}

.box.show-top > .box__face--left {
  width: var(--height-size);
  transform: rotateY(-90deg) translateZ(var(--half-height-size));
}

.box.show-front > .box__face--top,
.box.show-back > .box__face--top,
.box.show-right > .box__face--top,
.box.show-left > .box__face--top {
  height: var(--width-size);
  transform: rotateX(90deg) translateZ(var(--half-width-size));
}

.box.show-front > .box__face--bottom,
.box.show-back > .box__face--bottom,
.box.show-right > .box__face--bottom,
.box.show-left > .box__face--bottom {
  height: var(--width-size);
  transform: rotateX(-90deg)
    translateZ(calc(var(--height-size) - var(--half-width-size)));
}

.box__face {
  position: absolute;
  font-weight: bold;
  color: white;
  text-align: center;
}

.box__face h1 {
  color: #52c47b;
  display: inline-flex;
  justify-content: center;
  margin-top: 4rem;
  background-color: white;
  padding: 1rem;
}

.box__face--front,
.box__face--back {
  width: var(--width-size);
  height: var(--height-size);
}

.box__face--right,
.box__face--left {
  width: var(--width-size);
  height: var(--height-size);
}

.box__face--top,
.box__face--bottom {
  width: var(--width-size);
  height: var(--height-size);
}

.box__face--front {
  background: url("/react.jpg") no-repeat center center;
  background-size: cover;
  transform: rotateY(0deg) translateZ(var(--half-width-size));
}

.box__face--back {
  background: url("/opensource.jpeg") no-repeat center center;
  background-size: cover;
  transform: rotateY(180deg) translateZ(var(--half-width-size));
}

.box__face--right {
  background: url("/nodejs.webp") no-repeat center center;
  background-size: cover;
  transform: rotateY(90deg) translateZ(var(--half-width-size));
}

.box__face--left {
  background: hsla(0, 5%, 85%, 0.9);
  transform: rotateY(-90deg) translateZ(var(--half-width-size));
}

.box__face--top {
  background: hsla(0, 5%, 85%, 0.9);
  transform: rotateX(90deg) translateZ(var(--half-height-size));
}

.box__face--bottom {
  background: hsla(0, 5%, 85%, 0.9);
  transform: rotateX(-90deg) translateZ(var(--half-height-size));
}

.radio-group {
  z-index: 2;
}

.radio-group label {
  position: absolute;
  margin-right: 10px;
  cursor: pointer;
}

.radio-group input {
  display: none;
}

.labels {
  font-size: 22px;
  background-color: #fff;
  padding: 0.5rem 1rem;
  transform: scale(1);
  animation: pulse 2s infinite;
}

.labels:first-child {
  display: none;
}

.labels:nth-child(2) {
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}

.labels:nth-child(3) {
  top: 10px;
  left: 10px;
}

.labels:nth-child(4) {
  top: 50%;
  left: 10px;
  transform: translate(0, 50%);
}

.labels:nth-child(5) {
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.labels:nth-child(6) {
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.project-cards {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: 80%;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
