.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.about-card {
  position: relative;
  height: 80%;
  width: 30%;
  border-radius: 8px;
  background-color: #fff;
}

.about-card::before {
  position: absolute;
  border-radius: 8px;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #52c47b;
  transform-origin: 50% 50%;
  transform: rotate(10deg);
  z-index: -1;
}

.about-card-content {
  padding: 2rem;
}

.profile {
  text-align: center;
}

.user-image {
  width: 8.5rem;
  height: 8.5rem;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.about-me-text {
  margin-top: 2rem;
  font-size: 1.8rem;
  text-align: center;
  font-weight: bold;
}

.about-me-heading {
  margin-top: 2rem;
  text-align: center;
  font-weight: bold;
  color: #52c47b;
}

.links {
  text-align: center;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, 0);
  text-transform: uppercase;
}

.link-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.link-icons a {
  margin-top: 0.5rem;
}

.icon {
  font-size: 2.5rem;
  margin-right: 1rem;
}

.icon:hover {
  color: #52c47b;
}
