.skill-wrapper {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  text-align: center;
}

.skill-title {
  text-align: center;
  margin: 2rem auto;
  color: #52c47b;
  background-color: #fff;
  display: inline-flex;
  padding: 1rem;
}

.skill-cards {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0 75px;
}

.skill-card {
  margin: 2rem;
  display: inline-block;
  width: 240px;
  text-align: center;
}

.icon-container {
  height: 230px;
  width: 200px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.icon-container svg {
  font-size: 150px;
}

.inner-skew {
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: rgb(241, 241, 243);
  height: 230px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #fff;
  margin: -120px 0px 0px 0px;
  font-size: 14px;
  height: 260px;
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #52c47b;
  font-size: 18px;
}
