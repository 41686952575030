.project-card {
    margin: 1rem;
    width: 240px;
    min-height: 250px;
    text-align: center;
    /* background-color: #5F6979;
     */
     background-color: #ffffff;
     color:#000000;
    line-height: normal;
    border-radius: 6px;
    border-top: 3px solid var(--line-color);
    padding: 1rem;
    position: relative;
}

.project-card:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
}

.project-card-title {
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--line-color);
}

.project-card-content {
    font-size: 1.6rem;
    font-weight: normal;
}

.project-card .links {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    width: 100%;
}



.links a {
    display: flex;
    color: var(--line-color);
    text-decoration: none;
    font-size: 2.6rem;
    width: 50%;
    border-top: 1px solid var(--line-color);
    padding: 1rem;
}


.links a:first-child {
    border-right: 1px solid var(--line-color);

}

.links a svg{
    margin: auto;
}

.links a:hover>svg {
    animation: shake 800ms infinite;
}

@keyframes shake {
    100%,0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(25deg);
    }
}