.container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    overflow-x: hidden;
}

.timeline {
    width: 80%;
    height: auto;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.timeline ul {
    list-style: none;
}

.timeline ul li {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.timeline ul li:last-child {
    margin-bottom: 0;
}

.timeline-content h1 {
    font-weight: 600;
    font-size: 2rem;
    color: #52c47b
}

.timeline-content h5 {
    font-size: 1.2rem;
    color: gray
}

.timeline-content p {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0;
    font-weight: 300;
    color: black;
}

.sub-timeline-items{
    position: relative;
}

.sub-timeline-item p{
    margin-bottom: 0;
}

.sub-timeline-item:before{
    content: "";
    position: absolute;
    top: 18px;
    transform: translateX(-50%);
    width: 0.8px;
    height: calc(100% - 34px);
    box-shadow: -3px 0 0 rgb(218, 214, 214);
}

.sub-timeline-item p::before{
    content: "";
    position: absolute;
    top:50%;
    transform: translate(-28px,-50%);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgb(218, 214, 214);
}

.sub-timeline-item p{
    position: relative;
    padding-left: 20px;
    margin-bottom: 8px;
}

.sub-timeline-item p span{
    display: block;
    line-height: normal;
    font-size: 1.6rem;
    color:gray
}

@media only screen and (min-width: 768px) {
    .timeline:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0.8px;
        height: 100%;
        background-color: white;
        box-shadow: -3px 0 0 #c4bfbf;
    }

    .timeline ul li {
        width: 50%;
        position: relative;
        margin-bottom: 50px;
        transition: transform 1s ease-out, opacity 0.5s ease-in 0.5s;
        opacity: 0;
    }

    .timeline ul li:nth-child(odd) {
        float: left;
        clear: right;
        transform: translateX(-100%);
        border-radius: 20px 0px 20px 20px;

    }

    .timeline ul li:nth-child(even) {
        float: right;
        clear: left;
        transform: translateX(100%);
        border-radius: 0px 20px 20px 20px;
    }

    .timeline ul .fade-in {
        opacity: 1;
    }

    .timeline ul .fade-in:nth-child(odd) {
        transform: translateX(-30px);
    }

    .timeline ul .fade-in:nth-child(even) {
        transform: translateX(30px);
    }

    .timeline ul li::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: gray;
        top: 0px;
        transition: top 0.5s ease-out;
    }

    .timeline ul li:nth-child(odd)::before {
        transform: translate(50%, -50%);
        right: -30px;
    }

    .timeline ul li:nth-child(even)::before {
        transform: translate(-50%, -50%);
        left: -30px;
    }

    .timeline ul li:hover::before {
        background-color: #52c47b;
        top: 50%
    }
}